.contact {
    background-color: #26406B;
    width: 100%;
}

.branch1 {
    text-align: center;
    color: #FCD405;
}

.branch2 {
    text-align: center;
    color: #FCD405;
}

.mapContainerSection {
    display: flex;
}

.mapSection {
    width: 50%;
    flex: 1; /* Each column takes an equal amount of space */
    padding: 20px; /* Adding padding for content spacing */
}

.mapSection img {
    max-width: 100%; /* Ensure images don't exceed their container's width */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove extra space below inline images */
    margin: 0 auto; /* Center the image if needed */
}

.factorySection {
    max-width: 100%; /* Ensure images don't exceed their container's width */
}

.imgMap {
    width: 20%;
}

@media (max-width: 768px) {
    .mapContainerSection {
        display: block; /* Change the display property for mobile devices */
    }

    .mapSection {
        width: auto;
    }

    .branch1, .branch2  {
        font-size: 18px;
    }

    .imgMap {
        width: 40%;
    }

}

.dashed-line {
    border-top: 1px dashed white; /* Adjust the color and width as needed */
    margin: 10px 0; /* Adjust the margin as needed */
    width: 100%; /* Set the width to 100% to span the entire container */
}
