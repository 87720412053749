
.home {
    background-color: #FCD405;
    width: 100%;
}

.txtHead1 {
    font-family: Arial, sans-serif;
    font-size: 30px;
    font-weight: bold;
    -webkit-text-stroke: 1px blue; /* Width and color of the text stroke (for webkit browsers) */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: transparent; /* Make text fill transparent */
    -webkit-text-fill-color: white; /* Color inside the text (for webkit browsers) */
    font-style: italic;
    padding-left: 10px;
    height: 5px;
    text-align: center;
    /*background-color: #FCD405;*/
}

@media screen and (max-width: 768px) {
    .txtHead1 {
        font-size: 18px; /* Adjust the font size for mobile devices */
    }
}

.txtHead2 {
    font-family: Arial, sans-serif;
    font-size: 30px;
    font-weight: bold;
    -webkit-text-stroke: 1.6px red; /* Width and color of the text stroke (for webkit browsers) */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: transparent; /* Make text fill transparent */
    -webkit-text-fill-color: white; /* Color inside the text (for webkit browsers) */
    font-style: italic;
    text-align: center;
    height: 30px;
}

@media screen and (max-width: 768px) {
    .txtHead2 {
        font-size: 18px; /* Adjust the font size for mobile devices */
    }

    .responsive-image {
        width: 100%;
        height: auto;
        /*height: auto;*/
    }

    .text-center2 span{
        font-size: 20px;
    }
}


/*!* Media query for responsiveness *!*/
@media screen and (max-width: 768px) {
    .text-center2 span{
        font-size: 20px;
    }

    .image-container img {
        width: 100%; /* Set width to 100% on smaller screens */
        height: auto;
    }
}

/*!* Media query for smaller screens *!*/
@media screen and (max-width: 480px) {
    .text-center2 span{
        font-size: 18px;
    }

    .responsive-image {
        width: 100%;
        height: auto;
    }
}

.responsive-image {
    width: 100%;
    height: auto;
}

.text-center {
    font-size: 20px;
    text-align: center;
    padding-top: 10px;
}

.text-center2 {
    font-size: 20px;
    text-align: center;
    padding: 10px 80px;
}

.text-indented {
    text-indent: 20px; /* กำหนดการย่อหน้า */
    line-height: 1.6; /* กำหนดความสูงของบรรทัด */
    padding-left: 5px;
    padding-right: 20px;
}


.arrow-box {
    position: relative;
    background-color: #3DADD4;
    padding: 5px;
    width: 120px;
    border: 1px solid #3DADD4;
    border-radius: 5px;
    margin: 20px;
}

.arrow {
    position: absolute;
    width: 25px;
    height: 2px;
    border-left: 20px solid #3DADD4;
    border-right: 20px solid transparent;
    border-top: 45px solid transparent;
    border-bottom: 45px solid transparent;
    left: 128px;
    top: 50%;
    transform: translateY(-50%);
}

.spaced-table {
    display: flex;
    justify-content: center;
    border-collapse: separate;
    border-spacing: 20px; /* Adjust the value to set the desired space between columns */
    color: #2C3594;
    font-weight: bold;
}

/* Optional: Add some styling */
.spaced-table td {
    padding: 8px;
    /*border: 1px solid #ccc; !* Add borders for cell separation (optional) *!*/
}

.column-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
}
