/* Style for the header element */
.header {
    background-color: #2C3494;
    color: #fff;
    width: 100%;
}

/* Style for the navigation links */
.header nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    /*text-align: center;*/
}

.header nav ul li {
    display: inline;
    text-align: center;
    margin-right: 25px;
}

.header nav ul li a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.header nav ul li a:hover {
    color: #ffcc00; /* Change color on hover */
}

.active-link {
    color: red; /* Change text color for active link */
    font-weight: bold; /* Make active link bold */
}

.logo-text-container {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically center the content */
    text-align: center; /* Center align the text */
    padding-bottom: 20px;
    /*flex-direction: column;*/
}

.logo-text-container span {
    font-size: 28px;
    margin-left: 30px; /* Add left margin to the text */
}

.logo-image {
    max-width: 180px; /* Set maximum width for the logo */
    height: auto; /* Maintain aspect ratio */
}

.column {
    text-align: center;
}

.navFontSize {
    font-size: 20px;
}


/* For small screens (e.g., mobile phones) */
@media only screen and (max-width: 767px) {
    .logo-text-container span{
        font-size: 20px;
    }
}

/* For medium-sized screens (e.g., tablets) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .logo-text-container span{
        font-size: 28px;
    }

    .logo-image {
        max-width: 150px; /* Set maximum width for the logo */
        height: auto; /* Maintain aspect ratio */
    }

    .logo-image {
        max-width: 150px; /* Set maximum width for the logo */
        height: auto; /* Maintain aspect ratio */
    }
}

/* Media queries for responsive font sizes */
@media (max-width: 768px) {
    .navFontSize {
        font-size: 16px;
    }

    .logo-image {
        max-width: 100px; /* Set maximum width for the logo */
        height: auto; /* Maintain aspect ratio */
    }

}

@media (max-width: 480px) {
    .navFontSize {
        font-size: 14px;
    }

    .logo-image {
        max-width: 100px; /* Set maximum width for the logo */
        height: auto; /* Maintain aspect ratio */
    }
}
