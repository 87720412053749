.slideshow-container {
    max-width: 90%;
    margin: auto;
}

.slideshow-container-product {
    max-width: 100%;
    margin: auto;
    padding: 5px 5px 30px;
}


/* Optionally, style the legend/caption */
.legend {
    text-align: center;
    margin-top: 10px;
    font-style: italic;
    color: #888;
}

.imgProduct {
    /*height: 300px;*/
    width: 100%;
}
