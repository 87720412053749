
.footer {
    background-color: #2C3594;
    color: white;
    /*padding: 10px;*/
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}
.left-column,
.right-column,
.center-column {
    flex: 1;
    text-align: center; /* Adjust alignment as needed */
}

/*.left-column {*/
/*    text-align: left;*/
/*}*/

.center-column {
    font-weight: bolder;
    padding: 1px;
    font-size: 40px;
}


@media screen and (max-width: 768px) {
    .footer {
        /*padding: 15px;*/
    }
}

/* Adjust styles for even smaller screens */
@media screen and (max-width: 480px) {
    .footer {
        /*padding: 10px;*/
        /*position: fixed;*/
        bottom: 0;
    }
}
