.about {
    background-color: white;
    width: 100%;
}

.player-wrapper {
    display: flex;
    padding: 10px;
}


.video-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 10px 10px 60px;
    /*gap: 20px; !* Adjust the gap between videos *!*/
}

.video-item {
    width: calc(25% - 20px); /* Adjust the width for 4 videos in a row */
    margin-bottom: 20px; /* Adjust the bottom margin */
}

@media (max-width: 768px) {
    .video-item {
        width: 100%; /* Adjust for smaller screens to show 2 videos in a row */
    }
}

.channelYoutube {
    text-align: center;
    color: black;
}

.channelYoutube2 {
    text-align: center;
    color: #FCD405;
}
