.product {
    background-color: white;
    width: 100%;
}

.player-wrapper {
    display: flex;
    padding: 10px;
}

.popular-products {
    text-align: center;
    color: white;
    -webkit-text-stroke: 1px blue;
    text-shadow: 1px 1px 4px blue; /* Adjust shadow values as needed */
}
